
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.app-header {
  height: $header-height;
  box-shadow: $ui-default-box-shadow--small;
  width: 100%;
  background: $color-white;

  // -1px due to max-width approach, so that the design doesn't go into min-app-width
  @media screen and (max-width: (#{$min-app-width} - 1px)) {
    padding: 2.5%;
    overflow-x: auto;
  }

  .container {
    height: 100%;
  }

  .row {
    height: inherit;
    align-items: center;
  }

  &__logo {
    @include font(24, bold);
    text-align: center;
    color: $color-primary-light;
    display: flex;
    justify-content: flex-start;
    cursor: default;
  }

  &__children {
    margin-top: $header-height;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
  }

  &-default {
    position: fixed;
    top: 0;
    z-index: getIndex(header);
    width: 100vw;

    &--admin {
      ~ .app-header__children {
        margin-top: calc(#{$header-height} + #{$header-height-admin});
        // ui-default-measure because of footer margin-top
        min-height: calc(100vh - #{$header-height} - #{$header-height-admin} - #{$footer-height} - #{$ui-default-measure});

        .user-common-view__content {
          margin-top: -#{$header-height-admin};
        }
      }
    }
  }

  &__new-request {
    display: flex;
    justify-content: center;
  }

  &__logout {
    display: flex;
    justify-content: flex-end;
  }
}
