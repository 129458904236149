
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.error-container {
  min-height: calc(100vh - 80px - 216px);

  .error-wrapper {
    margin-top: $ui-default-measure3x;
    padding-top: $ui-default-measure3x;

    &__buttons {
      display: flex;
      justify-content: center;

      button {
        margin-right: $ui-default-measure;
        margin-left: $ui-default-measure;
      }
    }
  }
}
