
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-footer {
  &__buttons {
    > .row {
      justify-content: flex-end;
    }

    .base-button:not(:first-of-type) {
      margin-left: 40px;
    }
  }

  &--request-form {
    width: 100%;

    .row {
      .request-footer {
        &__sidebar {
          max-width: 336px;
          margin-right: 0;
        }

        &__buttons {
          max-width: 680px;
          margin: 0;

          .base-button:not(:first-of-type) {
            margin-left: 24px;
          }

          .base-button {
            @include font(14);

            &--outline {
              padding: 13px 16px;
            }

            &--fill {
              padding: 13px 36px;
            }
          }
        }

        &__col {
          display: flex;

          &--normal {
            width: 50%;
          }

          &--end {
            justify-content: flex-end;
          }
        }

        &__wrapper {
          display: flex;
          justify-content: flex-end;
          padding: 32px 0;
          border-top: 1px solid $color-text-light;

          &--renewal {
            flex-direction: column;
            align-items: flex-end;

            .request-footer {
              &__info {
                @include font(14);
                width: 52%;

                &--secondary {
                  width: 56%;
                }
              }

              &__container {
                margin: 16px 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                &--secondary {
                  margin-top: 0;
                }
              }

              &__button--back {
                color: $color-text;
                border: 1px solid $color-text;
              }

              &__col {
                &--small {
                  width: 40%;
                }

                &--normal {
                  width: 50%;
                }

                &--large {
                  width: 60%;
                  max-width: 336px;

                  justify-content: flex-end;

                  .base-button--outline {
                    margin-left: 8px;
                  }
                }

                &--hidden {
                  justify-content: flex-start;
                  margin-left: 36px;

                  .base-button--outline {
                    display: none;
                  }
                }
              }

              &__cancel {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: center;

                .base-button--link {
                  color: $color-text;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        &__cancel,
        &__container {
          width: 50%;
        }

        &__submit {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
