
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.thank-you {
  min-height: calc(100vh - #{$footer-height} + 20px);
  margin-top: -#{$form-padding-top};

  &--advisor {
    margin-top: 0;
    min-height: calc(100vh - #{$header-height});
  }

  &__wrapper {
    width: 100% !important;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;

    > h1 {
      text-transform: uppercase;
      color: $color-primary;
    }

    > h1,
    > p {
      // value based on app width plus padding to compensate for text - check HalfPageLayout.scss
      // Fixes IE 11 issues of text overlapping divs.
      width: calc(#{$min-app-width} / 2);
    }
  }

  &__description.copy {
    margin-bottom: 40px;
  }
}
