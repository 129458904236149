
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.toasts-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 40vw;
  margin-top: 20px;
  top: 20px;
  left: 30vw;
  margin-bottom: 0;
  z-index: getIndex(toast);
}

.toast {
  $module: &;
  $status-list: (
      (success, $color-success, $color-success--light),
      (error, $color-warning, $color-warning--light),
      (warning, $color-action, $color-action--light)
  );

  position: relative;
  width: 40vw;
  min-height: 68px;
  border-left-style: solid;
  border-left-width: 3px;
  color: $color-text-dark;
  display: flex;
  align-items: center;
  padding: $ui-default-measure $ui-default-measure2x;

  @each $status, $color, $color--light in $status-list {
    &--#{$status} {
      border-left-color: $color;
      background-color: $color--light;

      #{$module}__icon svg path {
        fill: $color;
      }
    }
  }

  &__icon {
    align-self: flex-start;
    padding-top: 6px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  &__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: #000;
      }
    }
  }

  &__title {
    @include font-weight(500);
    padding-bottom: 4px;
    width: 90%;
  }
}
//
.toast__transition-enter {
  opacity: 0.01;
}

.toast__transition-enter.toast__transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.toast__transition-leave {
  opacity: 1;
}

.toast__transition-leave.toast__transition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
