
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-upload-box {
  padding: $ui-default-measure2x $ui-default-measure2x $ui-default-measure3x $ui-default-measure2x;
  border: 1px solid $color-primary;
  margin: $ui-default-measure3x 0 0;

  &--no-style {
    padding: 0;
    border: none;
  }

  &__title,
  &__conditions {
    margin: 5px 0;
  }

  &__submit-button {
    margin-top: $ui-default-measure;
  }

  &--renewal {
    .base-upload {
      &__list-item {
        margin: 16px 0;
      }

      &-input__button {
        margin-top: 0;
      }

      &__list-item-content {
        padding: 10px 0;
      }

      &__list-item-remove {
        text-decoration: underline;
        color: $color-rejected;
      }
    }
  }
}
