
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.environment-bar {
  width: 100%;
  text-align: center;
  background-color: rgba(red($color-action), green($color-action), blue($color-action), 0.15);;
}
