
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.user-groups-edit {
  padding-top: 50px;

  .base-box {
    padding-top: $ui-default-measure;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    color: $color-warning;
    @include font(14);
  }

  td:last-of-type {
    text-align: right;
  }
}
