
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-button {
  @include font(16);
  border: 1px solid $color-primary;
  text-shadow: none;
  box-shadow: none;
  border-radius: 0;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: default;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $ui-default-measure 10px;
  max-width: 500px;

  &:active,
  &:focus {
    outline: 0;
  }

  &--active {
    cursor: pointer;
  }
}

.base-button--fill {
  background-color: $color-primary;
  color: $color-white;

  &:hover {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
  }

  &.base-button--disabled {
    color: $color-white;
    background-color: $color-text-light;
    border-color: $color-text-light;
  }
}

.base-button--outline {
  background-color: $color-white;
  color: $color-primary;

  &:hover {
    border-color: $color-primary-dark;
    color: $color-primary-dark;
  }

  &.base-button--disabled {
    color: $color-text-light;
    border-color: $color-text-light;
  }
}

.base-button--link {
  color: $color-primary;
  background-color: transparent;
  border-color: transparent;
  padding: 10px 0;

  &:hover {
    color: $color-primary-dark;
  }
}

.base-button--small {
  height: 36px;
  padding: 6px 10px;
}

.base-button--large {
  height: 52px;
  padding: 14px 10px;
}

.base-button--left {
  justify-content: flex-start;
}

.base-button--full {
  width: 100%;
}

.base-button__content {
  visibility: hidden;
  display: flex;
  align-items: center;

  &--visible {
    visibility: visible;
  }
}

.base-button__loader {
  display: none;

  &--visible {
    display: block;

    &.base-loader-spinner {
      bottom: calc(50% - 12px);

      &--small {
        bottom: calc(50% - 6px);
      }

      &--large {
        bottom: calc(50% - 18px);
      }
    }
  }
}

.base-button__category {
  width: 100%;
  border: none;
  border-left: 2px solid transparent;
  border-top: 1px solid $color-light-background;
  color: $color-text-dark;

  &:hover {
    border-top: 1px solid $color-light-background;
    background-color: $color-background-purpule-light;
  }

  &--active {
    border-left: 2px solid $color-primary;
    color: $color-primary;
    background-color: $color-background-purpule-light;
  }

  &--secondary {
    border: none;

    &:hover {
      border-top: none;
      background-color: transparent;
      color: $color-primary;
    }
  }

  &--secondary {
    background-color: transparent;
  }
}

.base-button__intro {
  border: none;
  color: $color-white;
  background-color: $color-success;

  &--secondary {
    background-color: transparent;
    color: $color-text-dark;
    text-decoration: underline;
  }
}
