
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/menu/style/index.css';
@import '~antd/lib/dropdown/style/index.css';

.ant-dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.ant-dropdown-menu-item {
  position: relative;
  padding: 0 10px;

  &:not(:last-of-type):after {
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: 1px;
    bottom: 0;
    background: $color-text-light;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $color-white;
}

.base-dropdown {
  &__icon {
    margin-right: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 14px 0;
    color: $color-text-dark;
    @include font(16);

    &--disabled {
      cursor: not-allowed !important;
      opacity: 0.4;
    }
  }
}
