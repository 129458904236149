
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.final-terms {
  padding-top: 60px;

  &__download-button {
    margin-top: 25px;
  }
}
