
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.login-messages {
  padding-top: calc(98px + 26px + 26px + 4px); // sum of .user-common-view__title + heading-primary margins + label adjustment

  &__item {
    margin-bottom: 32px;
  }

  .heading {
    line-height: 24px;
    margin-top: 0;
  }

  .copy {
    line-height: 22px;
  }
}
