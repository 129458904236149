
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.ant-select-selection__clear {
  background-color: transparent;
  right: 50px;
}

.multiple-select {
  .ant-select-selection__rendered {
    margin-right: 5px;
  }

  .ant-input, .ant-calendar-picker-input, .ant-select-selection {
    height: auto;
  }

  .ant-select-selection--multiple  {
    .ant-select-selection__choice {
      width: 96%;
      max-width: 96%;
      margin-right: 0px;
      line-height: 26px;
      height: 26px;
  
      div {
        line-height: 26px;
      }
    }

    .ant-select-arrow {
      top: 20px;
    }
  }
}
