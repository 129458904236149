
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-deletion-modal {
  &__footer {
    text-align: center;
  }

  &__header {
    padding: 0 0 6px;
    text-align: center;
  }

  &__header-icon {
    margin: 16px 0 8px;
  }
}
