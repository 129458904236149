
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-box {
  box-shadow: $ui-default-box-shadow--small;
  background: $color-white;
  padding: 20px 80px 40px;
  margin-bottom: 40px;

  @media screen and (min-width: $min-app-width) {
    padding: 30px 80px 45px;
  }

  &--lifted {
    border: 1px solid $color-light-background;
    box-shadow: $ui-default-box-shadow;
  }

  &--borderless {
    border: none;
    box-shadow: none;
    padding: 0 60px;
    margin: 0;

    @media screen and (min-width: $min-app-width) {
      padding: 0 100px;
    }
  }

  &--box {
    border: 1px solid $color-primary;
    box-shadow: none;
    padding: 16px 10px;
    background-color: rgba(68, 49, 175, 0.05);
    color: $color-primary;

    &.code-of-conduct {
      border: 1px solid $color-rejected;
      background-color: rgba(235, 87, 87, 0.15);
      color: $color-rejected;
    }
  }
}
