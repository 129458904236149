
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.search-form {
  .ant-modal-footer,
  .ant-modal-header {
    padding: 16px;
  }

  .ant-modal-title {
    color: #ffffff;
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .row:not(:last-of-type) {
    margin-bottom: 0px;
  }

  .base-box__heading {
    margin: 16px 0;
  }

  #search-form__status, #search-form__ombudsman, #search-form__countries {
    position: relative;
  }
}
