
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.half-page {
  min-height: inherit;

  &__left {
    background-color: $color-primary;
  }

  &__right  {
    margin-left: 0 !important;

    > div {
      // value based on app width plus padding to compensate for text
      width: calc(#{$min-app-width} / 2 + 25px);
    }
  }
}

// -1px due to max-width approach, so that the design doesn't go into min-app-width
@media screen and (max-width: (#{$min-app-width} - 1px)) {
  .half-page__right {
    overflow-x: hidden;
  }

  .col-6.half-page__left {
    display: none;
  }

  .col-6.half-page__right {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;

    > * {
      max-width: calc(#{$min-app-width / 2 - 25px});
      margin-right: 0;
    }
  }
}
