
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.list {
  $module: &;
  position: relative;
  min-height: 150px; // for presentation purposes, especially when loader is visible
  margin-top: 35px;


  &__filters {
    flex-direction: column;
    background: #f1f2f2;
    padding: 16px;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;

    .base-icon {
      cursor: pointer;
      padding: 0px 16px;
      box-sizing: content-box;
      border-right: 1px solid #d1d2d2;
    }

    .base-icon:last-of-type {
      border: none;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;

    div:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: $ui-default-measure3x;

    .heading--hero {
      text-align: center;
      width: 100%;
      margin-bottom: 0;
    }

    .heading--secondary {
      text-align: left;
    }

    .heading--subsection {
      text-align: center;
      margin-top: 0;
    }
  }

  &__link-button {
    margin: 30px 0;
  }

  &__header {
    &-new-request {
      display: flex;
      justify-content: center;
    }

    &-logout {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__empty {
    margin-top: 16px;
    background: #f1f2f2;
    padding: 16px;
    text-align: center;
  }
}
