
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request__form-input-offset {
  padding-left: 45px;
}

.request-invoice__contact {
  &-header {
    margin: 30px 0 $ui-default-measure;
  }

  .ant-form-item-label label::after {
    content: ''; // remove default colon added by antd to label
  }
}
