
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/switch/style/index.css';

$switchBackground: #221F1F;

.ant-switch {
  height: 14px;
  line-height: 12px;
  background-color: rgba(red($switchBackground), green($switchBackground), blue($switchBackground), 0.26);
  min-width: 40px;

  &-checked {
    background-color: rgba(red($color-success), green($color-success), blue($color-success), 0.5);
  }
}

.ant-switch::after {
  height: 20px;
  width: 20px;
  top: -4px;
  left: -1px;
  background-color: $color-light-background;
}

.ant-switch-checked::after {
  left: 100%;
}

.ant-switch-checked::after {
  margin-left: 2px;
  background-color: $color-success;
}

