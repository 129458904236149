
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.upload-summary-modal {
  &__content {
    display: flex;
    justify-content: space-around;
    text-align: left;
    max-height: 500px;
    overflow: auto;
  }

  &__success-title {
    color: $color-success;
  }

  &__error-title {
    color: $color-warning;
  }
}
