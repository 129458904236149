
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.company-information {
  &__footer-buttons {
    display: flex;
    margin-top: $ui-default-measure2x;
    border: 1px solid $color-primary;
    padding: 16px 24px;

    button {
      margin-right: $ui-default-measure2x;
    }
  }

  &__main-heading {
    margin-top: 0;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-column > .field-container {
    margin-bottom: 0px;
  }
}
