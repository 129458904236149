
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/fira-sans-300.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-400.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/fira-sans-500.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-sans-700.woff') format('woff');
}

@import 'partials/spacing-helpers';
@import 'partials/common';
@import 'partials/grid';
@import 'partials/icons';
@import 'partials/normalize';
@import 'partials/typography';
