
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-icon {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;
    width: auto;
  }

  &--small {
    height: $ui-default-measure2x;
    min-width: $ui-default-measure2x;
    max-width: $ui-default-measure2x;

    svg {
      height: $ui-default-measure2x;
    }
  }

  &--large {
    height: $ui-default-measure3x;
    min-width: $ui-default-measure3x;
    max-width: $ui-default-measure3x;

    svg {
      height: $ui-default-measure3x;
    }
  }

  &--xlarge {
    height: $ui-default-measure4x;
    min-width: $ui-default-measure4x;
    max-width: $ui-default-measure4x;

    svg {
      height: $ui-default-measure4x;
    }
  }

  &--xxlarge {
    height: $ui-default-measure5x;
    min-width: $ui-default-measure5x;
    max-width: $ui-default-measure5x;

    svg {
      height: $ui-default-measure5x;
    }
  }

  &--xxxlarge {
    height: $ui-default-measure6x;
    min-width: $ui-default-measure6x;
    max-width: $ui-default-measure6x;

    svg {
      height: $ui-default-measure6x;
    }
  }

  &--white path,
  &--white rect.fill {
    fill: $color-white;
  }

  &--gray path,
  &--gray rect.fill {
    fill: $color-text;
  }

  &--dark path,
  &--dark rect.fill {
    fill: $color-text-dark;
  }

  &--white polyline,
  &--white path.stroke,
  &--white rect.stroke {
    stroke: $color-white;
  }

  &--gray polyline,
  &--gray path.stroke,
  &--gray rect.stroke {
    stroke: $color-text;
  }

  &--dark polyline,
  &--dark path.stroke,
  &--dark rect.stroke {
    stroke: $color-text-dark;
  }

  &--clickable {
    cursor: pointer;
  }
}
