@mixin center($horizontal: -50%, $vertical: -50%) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(#{$horizontal}, #{$vertical})
}

@mixin font-name() {
  font-family: $global-font, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@mixin font-weight($font-weight) {
  @include font-name();
  font-style: normal;
  font-weight: $font-weight;
}

@mixin font-size($value: 16) {
  font-size: $value + px;
}

@mixin font($font-size: 16, $font-weight: normal) {
  @include font-size($font-size);
  @include font-weight($font-weight);
}
