$gutter: 15px;
$gutter2x: 2 * $gutter;

.container {
  max-width: $min-app-width;
  width: 95%;
  margin: 0 auto;

  @media screen and (min-width: 1440px) {
    // max-width larger to match design width
    max-width: calc(#{$min-app-width} + 20px);
    width: 85%;
  }
}

.row {
  width: 100%;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: $ui-default-measure2x;
  }
}

.row [class^="col"] {
  margin: 0 $gutter;
}

.row [class^="col"]:first-of-type {
  margin-left: 0;
}

.row [class^="col"]:last-of-type {
  margin-right: 0;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

$columns: 12, 6, 4, 3, 12/5, 2, 12/7, 12/8, 12/9, 12/10, 12/11, 1;

@for $i from 1 through length($columns) {
  $width: (100% / nth($columns, $i));

  .col-#{$i} {
    max-width: $width;
    min-width: calc((#{$width}) - #{$gutter2x});
    flex: $i;
    // required for IE heights to work properly
    min-height: inherit;
  };
}
