
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/modal/style/index.css';

$iconMeasure: 56px;

.ant-modal-header,
.ant-modal-footer {
  border: none;
}

.ant-modal-header {
  padding: 35px $ui-default-measure3x $ui-default-measure2x;
  background: $color-primary;
}

.ant-modal-close-x svg path {
  fill: $color-white;
}

.ant-modal-footer button + button {
  margin: 0;
}

.base-modal__header {
  text-align: center;
  position: relative;

  &-icon {
    height: $iconMeasure;
    min-width: $iconMeasure;
    max-width: $iconMeasure;
    margin-bottom: 8px;

    svg {
      height: $iconMeasure;
      min-width: $iconMeasure;
      max-width: $iconMeasure;
    }
  }

  &-close-icon {
    position: absolute;
    top: -28px;
    right: -12px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .base-icon {
      margin: 0;
    }
  }

  .heading {
    margin: 0;
  }
}

.base-modal__content {
  text-align: center;
}

.base-modal__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;

  .base-button:not(:last-of-type) {
    margin-bottom: $ui-default-measure2x;
  }
}

// Antd animations
.fade-enter,
.fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}

.fade-leave {
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.zoom-enter,
.zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}

.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-enter,
.zoom-appear {
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
