
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-box__heading {
  display: flex;
  align-items: center;
  margin: 32px 0 $ui-default-measure2x;

  span {
    @include font(16);
    color: $color-primary;
    padding: 0 $ui-default-measure2x;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;
  }
}
