
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-deletion-details-modal {
  &__content {
    margin: 24px 24px 0;

    .base-upload-box {
      border: 0;
      margin: 8px 0 0;
      padding: 0;
    }

    .ant-upload .ant-upload-btn {
      padding: 0;
    }
  }

  &__text {
    margin: 0 0 24px;
  }

  &__deletion-date {
    label {
      display: block;
      font-weight: bold;
      margin: 0 0 8px;
    }
  }

  &__delete-button {
    text-align: center;
  }
}
