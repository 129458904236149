@mixin setFontValues($font-size, $line-height) {
  @include font($font-size);
  line-height: $line-height;
}

.heading {
  @include font-weight(400);
  color: $color-text-dark;

  &--hero {
    @include setFontValues(48, 77px);
  }

  &--primary {
    @include setFontValues(40, 65px)
  }

  &--secondary {
    @include setFontValues(33, 53px)
  }

  &--tertiary {
    @include setFontValues(28, 45px)
  }

  &--section {
    @include setFontValues(23, 38px)
  }

  &--subsection {
    @include setFontValues(19, 27px)
  }

  &--extra-light {
    @include font-weight(200);
  }

  &--color-white {
    color: $color-white;
  }

  &--color-primary {
    color: $color-primary;
  }

  &--color-light {
    color: $color-text-light;
  }

  &--bold {
    @include font-weight(700);
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.copy {
  @include font(16, 400);
  color: $color-text;
  line-height: 26px;
  margin: 0;

  &--small {
    @include font(12);
    line-height: 19px;
  }

  &--color-white {
    color: $color-white;
  }

  &--color-primary {
    color: $color-primary;
  }

  &--color-light {
    color: $color-text-light;
  }

  &--color-dark {
    color: $color-text-dark;
  }

  &--bold {
    @include font-weight(700);
  }

  &--light {
    @include font-weight(300);
  }
}
