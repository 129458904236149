
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-status {
  display: inline-block;
  @include font(14);
  height: $ui-default-measure3x;
  padding: 4px;
  text-align: center;
  min-width: 130px;
  border-radius: 12px;
  color: $color-primary-x-dark;

  &__wrapper {
    cursor: default;

    &--request-details,
    &--request-form {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-light-background;
      margin-bottom: 45px;
      padding: 7px 0;

      & > div {
        margin: 0 30px;
      }

      .base-status {
        &--draft,
        &--approved,
        &--in_review,
        &--update_required,
        &--rejected {
          margin: 0 30px 0 15px;
        }

        &--approved {
          color: $color-text-dark;
        }

        &--in_review {
          color: $color-white;
          background-color: $color-review;
        }

        &__switch {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          align-items: center;

          .base-icon {
            margin-right: 12px;
          }
        }

        &__date {
          display: flex;
          margin: 0;

          .base-status__info {
            margin: 0 30px;
          }
        }
      }
    }
  }

  &--draft {
    background: $color-primary-x-dark;
    color: $color-white;
  }

  &--in_review {
    background: $color-text-light;
  }

  &--approved {
    background: $color-success;
    color: $color-white;
  }

  &--update_required {
    background: $color-action;
  }

  &--rejected {
    background: $color-warning;
    color: $color-white;
  }

  &__info {
    margin-top: $ui-default-measure3x;

    &-label,
    &-value {
      @include font(16, 300);
      line-height: 26px;
      color: $color-text-dark;
      margin: 4px 0;
    }

    &-value {
      @include font-weight(400);
    }
  }

  &__switch {
    margin-top: $ui-default-measure3x;
  }
}
