
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(#{$request-height} - #{$form-padding-top});
  margin-bottom: 30px;

  &__footer {
    margin-top: auto;
    box-shadow: $ui-default-box-shadow--reversed;
    height: 125px;

    > * {
      height: inherit;
      display: flex;
      align-items: center;
    }
  }

  &__footer--summary {
    justify-content: flex-end;
  }

  &__info {
    margin-top: $ui-default-measure3x;

    &-label,
    &-value {
      @include font(16, 300);
      line-height: 26px;
      color: $color-text-dark;
      margin: 0;
    }

    &-value {
      @include font-weight(400);
    }

    .copy {
      margin-top: $ui-default-measure2x;
    }
  }

  &__go-back {
    margin: $ui-default-measure 0 45px;
  }

  .multiline-button {
    min-height: 36px;
    height: auto;

    .base-button__content {
      white-space: normal;
    }
  }

  &--request-details {
    .request-wrapper__sidebar {
      max-width: 265px;
      margin-right: 0;
    }

    .request-wrapper__category {
      &--bottom {
        margin-top: 55px;
      }
    }

    .base-box {
      &--borderless {
        padding: 0 30px 0 90px;
      }

      &--box {
        margin: 0 30px 0 90px;
      }
    }
  }

  &--request-form {
    .row {
      .col-3 {
        &.request-wrapper__sidebar {
          position: relative;
          z-index: getIndex(detailsEditButton);
          max-width: 336px;
          margin: 0 0 -100px 0;
        }
      }

      .col-8 {
        &.request-wrapper__form {
          max-width: 680px;
          margin: 0;

          @media screen and (min-width: $medium-app-width) {
            max-width: 750px;
          }
        }
      }

      .col-12 {
        margin: 0;
      }
    }

    .base-box--borderless {
      padding: 0 150px 0 40px;
    }

    .base-box {
      &--box {
        margin: 16px 150px 0 40px;

        &.code-of-conduct {
          margin-bottom: 40px;
        }
        &.renewal-fee-agreement{
          margin: 0 0 20px 0;
          color: $color-text-dark;
          a {
            text-decoration: underline;
          }
        }
      }

      &.code-of-conduct {
        &--renewal {
          .code-of-conduct {
            &__checkbox {
              display: none;
              opacity: 0;
            }

            &__info {
              margin: 24px 0;
            }
          }
        }

        &--secondary {
          .code-of-conduct__subtitle {
            margin-top: 32px 0 24px;
          }
        }
      }
    }

    .base-summary__section {
      &.additional_recipients-section {
        display: none;
        opacity: 0;
      }
    }

    .base-summary__section-wrapper {
      .base-summary__section-heading {
        padding: 0 40px;
      }
    }

    .field-container {
      input,
      .ant-select {
        max-width: 100%;
        width: 100%;
      }
    }

    .base-hint {
      left: calc(100% + 30px);
    }

    .code-of-conduct {
      .base-hint {
        left: calc(100% + 44px);
        top: -15px;
      }
    }

    .base-upload-box {
      margin-top: 16px;
    }

    .request-form {
      &--criminal-record,
      &--conduct-training {
        .field-container {
          margin-bottom: 0;
        }
      }
    }

    .request-wrapper {
      &__stepper {
        position: sticky;
        top: 26%;
        height: 474px;

        &--amendment {
          top: 39%;
        }

        .ant-steps-item {
          &-finish,
          &-process,
          &-wait {
            height: 68px;

            .ant-steps-item {
              &-content {
                .ant-steps-item-title {
                  margin-top: 3px;
                  @include font(18);
                  font-weight: 400;
                  padding-right: 0;
                  color: $color-primary;
                }
              }

              &-tail {
                left: 20px;
                top: 7px;
                height: 92%;

                &::after {
                  background-color: $color-text-light;
                }
              }
            }

            &:not(.ant-steps-item-finish) .ant-steps-icon {
              visibility: visible;
            }
          }

          &-wait {
            .ant-steps-item {
              &-icon {
                background: rgba(0, 0, 0, 0.38);
                color: $color-text;
                border: none;
              }

              &-content {
                .ant-steps-item-title {
                  color: $color-text;
                }
              }
            }
          }

          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            @include font-weight(500);

            .ant-steps-icon {
              top: 0;
            }
          }

          &-process {
            height: 110px;

            @media screen and (min-width: $medium-app-width) {
              height: 125px;
            }

            .ant-steps {
              &-item {
                &-icon {
                  background-color: $color-primary;
                }

                &-tail {
                  height: 94%;
                  top: 9px;
                }
              }
              &-icon {
                color: $color-white;
              }
            }
          }
        }
      }

      &__main-heading {
        margin-top: 0;
      }

      &__footer {
        height: auto;
        box-shadow: none;
        margin-top: 32px;

        &.fields-activity {
          position: relative;
          bottom: 124px;

          &--summary {
            bottom: 22px;
          }
        }
      }
    }
  }
}
