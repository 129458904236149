
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.prospectus-details {

  &__go-back {
    margin: $ui-default-measure 0 45px;
  }

  &__summary-content {
    position: relative;
    > button {
      z-index: getIndex(header);
    }
  }

  &__controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: getIndex(header);

    > :last-child:not(:only-child) {
      margin-left: 10px;
    }
  }

  &__additional-files {
    margin-top: $ui-default-measure2x;

    .base-button {
      margin-top: $ui-default-measure2x;
      min-width: 140px;
    }
  }
}
