
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@mixin setSize($value) {
  width: $value;
  height: $value;
  left: calc(50vw - #{$value / 2});
  bottom: calc(50vh - #{$value / 2});
}

.base-loader-spinner,
.base-loader-spinner:after {
  border-radius: 50%;
  width: $ui-default-measure3x;
  height: $ui-default-measure3x;
}
.base-loader-spinner {
  $standardSize: 12px;
  $largeSize: 36px;

  position: fixed;
  left: calc(50vw - #{$standardSize});
  bottom: calc(50vh - #{$standardSize});
  border: 1px solid;
  border-color: $color-primary $color-primary $color-primary transparent;
  animation: loaderAnimation 1.1s infinite linear;

  &--component {
    position: absolute;
    left: calc(50% - #{$standardSize});
    bottom: calc(50% - #{$standardSize});

    &.base-loader-spinner--small {
      left: calc(50% - #{$standardSize / 2});
      bottom: calc(50% - #{$standardSize / 2});
    }

    &.base-loader-spinner--large {
      left: calc(50% - #{$largeSize / 2});
      bottom: calc(50% - #{$largeSize / 2});
    }
  }

  &--white {
    border-color: transparent $color-white $color-white $color-white;
  }

  &--small {
    @include setSize($standardSize);
  }

  &--large {
    @include setSize($largeSize);
    border-width: 2px;
  }
}

@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
