html, body, div, span, applet, object, iframe, blockquote, pre, abbr, acronym,
address, big, cite, code,del, dfn, font, ins, kbd, q, s, samp, small, strike,
sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  line-height: normal;
}
p {
  margin-top: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1 a, h2 a, h3 a, h4 a, h5 a,
a h1, a h2, a h3, a h4, a h5,
input[type='submit'],
a img,
a {
  text-decoration: none;
  transition:all .3s ease;
  position: relative;
  color: inherit;
}

a:focus,
a:active,
a:hover {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

a.non-relative {
  position: initial
}

html, body {
  min-width: $min-app-width;
  min-height: 100vh;

  //@media screen and (min-width: $min-app-width) {
  //  overflow-x: hidden;
  //}
}

body {
  padding-right: 0 !important;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div#root {
  min-height: inherit;
}

.ant-form-explain {
  line-height: 18px;
}
