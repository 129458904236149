
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/date-picker/style/index.css';
@import '~antd/lib/input/style/index.css';
@import '~antd/lib/select/style/index.css';
@import '~antd/lib/form/style/index.css';
@import '~antd/lib/checkbox/style/index.css';
@import '~antd/lib/radio/style/index.css';

// form fields base styles
.field-container {
  margin-bottom: $ui-default-measure2x;

  input,
  .ant-select {
    max-width: 500px;
  }
}

.ant-form-item {
  margin-bottom: 0;
}

.field-container,
.ant-calendar-picker {
  width: 100%;
}

.ant-input,
.ant-calendar-picker-input,
.ant-select-selection {
  width: 100%;
  border-radius: 0;
  border: 1px solid $color-light-background;
  border-bottom: 1px solid $color-input-border;
  height: 40px;
  background-color: $color-light-background;
  color: $color-text-dark;
  transition: none;

  &:hover {
    border: 1px solid $color-primary;
  }

  &:focus,
  &:active {
    outline: 0;
    border: 1px solid $color-primary;
    box-shadow: none;
  }
}

.ant-input[disabled],
.ant-input[disabled]:hover {
  border: 1px solid lighten($color-light-background, 3%);
  border-bottom: 1px solid $color-input-border;
  background-color: lighten($color-light-background, 3%);
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $color-primary;
}

.ant-select-arrow-icon {
  position: relative;
  top: -3px;
}

.ant-select-selection__rendered,
.ant-select-selection-selected-value {
  line-height: 40px;
}

.ant-form-explain {
  margin-top: 2px;
}

.ant-form-item-label {
  color: $color-text-dark;
  @include font(16, 300);
  line-height: 26px;
}

.ant-form-item-control {
  line-height: 16px;
}

// Apply proper styles to condition {
.has-error .ant-form-explain {
  position: absolute;
  max-width: 100%;
  white-space: normal;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  border: 1px solid $color-rejected;
}

.has-error .ant-form-explain, .has-error .ant-form-split {
  color: $color-rejected;
}

.has-error .ant-input:focus,
.has-error .ant-select .ant-select-selection,
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border: 1px solid $color-rejected;
  box-shadow: none;
}

.ant-select-open .ant-select-selection {
  border: 1px solid $color-primary;
  box-shadow: none;
}

// Position feedback icon properly (by default it's too low)
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  margin-top: -11px;
}

.show-help-leave,
.show-help-leave.show-help-leave-active,
.show-help-enter,
.show-help-enter.show-help-enter-active {
  animation-duration: 0ms;
  position: absolute;

  * {
    animation-duration: 0ms;
  }
}

// Apply proper colors to feedback icons.

.anticon-close-circle {
  background: $color-light-background;

  svg path {
    fill: darken($color-light-background, 30%);
  }
}

.has-success.has-feedback .ant-form-item-children-icon {
  color: $color-success;
}

.has-error.has-feedback .ant-form-item-children-icon svg path {
  fill: $color-rejected;
}

// Open calendar color overrides
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border: 1px solid $color-primary;
}

.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-date:hover,
.ant-calendar-month-panel-month:hover,
.ant-calendar-year-panel-year:hover {
  background: lighten($color-primary, 50%);
}

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-date:active,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  background: $color-primary;
}

.ant-calendar-today .ant-calendar-date {
  color: $color-primary;
  border-color: $color-primary;
}

.ant-calendar-header a:hover {
  color: $color-primary;
}


// CHECKBOX

.ant-checkbox {
  top: 1px;

  &-wrapper {
    @include font(16);
    display: flex;
    color: $color-text-dark;
    align-items: flex-start;
    margin: $ui-default-measure 0 0;
  }

  &-inner {
    height: $ui-default-measure3x;
    width: $ui-default-measure3x;
    border-radius: 0;
    border-color: $color-primary;

    &:after {
      width: $ui-default-measure;
      height: $ui-default-measure2x;
      top: 40%;
      left: 18%;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $color-primary;
  background-color: $color-primary;

  &:after {
    border-width: 1px;
  }
}

span.ant-radio + *,
span.ant-checkbox + * {
  padding: 0 $ui-default-measure3x 0 12px;
}

.has-error {
  margin-bottom:25px;
}

.has-error .ant-checkbox-inner {
  border-color: $color-rejected;
}

.has-error .ant-checkbox-wrapper {
  border:1px solid $color-rejected;
  margin-bottom: 10px;
}


.has-success.has-feedback .ant-checkbox-wrapper ~ .ant-form-item-children-icon,
.has-warning.has-feedback .ant-checkbox-wrapper ~ .ant-form-item-children-icon,
.has-error.has-feedback .ant-checkbox-wrapper ~ .ant-form-item-children-icon,
.is-validating.has-feedback .ant-checkbox-wrapper ~ .ant-form-item-children-icon {
  display: none;
}

.has-error .ant-checkbox-wrapper ~ .ant-form-explain {
  margin-top: -10px;
}


// RADIO

.ant-radio {
  &-wrapper {
    @include font(16);
    color: $color-text-dark;
    display: inline-flex;
    align-items: flex-end;
    margin: $ui-default-measure 0;
  }

  &-inner {
    height: $ui-default-measure3x;
    width: $ui-default-measure3x;
    border-color: $color-primary;

    &:after {
      top: 3px;
      left: 3px;
      width: $ui-default-measure2x;
      height: $ui-default-measure2x;
      border-radius: $ui-default-border-radius--circle;
      background: $color-primary;
    }
  }
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $color-primary;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-primary;
  background-color: $color-white;

  &:after {
    border-width: 1px;
  }
}

.ant-radio-checked::after {
  border: 1px;
}

.has-error .ant-radio-inner {
  border-color: $color-rejected;
}

.has-success.has-feedback .ant-radio-group ~ .ant-form-item-children-icon,
.has-warning.has-feedback .ant-radio-group ~ .ant-form-item-children-icon,
.has-error.has-feedback .ant-radio-group ~ .ant-form-item-children-icon,
.is-validating.has-feedback .ant-radio-group ~ .ant-form-item-children-icon {
  display: none;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.checkbox-text {
  @include font(16);
  color: $color-text-dark;
}


// Radio Group Visual

.base-radio-group--visual .ant-radio-wrapper {
  margin: 0 $ui-default-measure;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.base-radio--single {
  display: flex;
  align-items: center;

  .radio-text {
    margin-left: $ui-default-measure;
  }
}

.base-radio-group--visual .ant-radio {
  min-width: 140px;
  padding: 10px;
  border: 1px solid $color-primary;
  min-height: 150px;
  margin-right: 8px;
  text-align: center;

  + span {
    @include center;
    text-align: center;
    width: 140px;
    white-space: normal;
    @include font(15);
  }

  &-checked {
    background: $color-primary;

    + span {
      color: $color-white;
    }
  }
}

.base-radio-group--visual .ant-radio-inner {
  display: none;
}

.base-radio-group--visual .ant-radio-wrapper:hover .ant-radio,
.base-radio-group--visual .ant-radio:hover .ant-radio-inner,
.base-radio-group--visual .ant-radio-input:focus + .ant-radio-inner {
  border-color: $color-primary;
}

.has-error .base-radio-group--visual .ant-radio {
  border-color: $color-rejected;
}

textarea.ant-input {
  min-height: 100px;
  resize: none;
}

.base-input__hint {
  margin-top: 5px;
}

.base-fetch-select-dropdown {
  position: relative;

  ul {
    min-height: 30px;

    li {
      min-height: 20px;
    }
  }

  .base-loader-spinner {
    left: $ui-default-measure !important;
    top: $ui-default-measure !important;
  }
}

