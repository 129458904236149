
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-deletion-confirmation-modal {
  &__content {
    text-align: center;
  }

  &__text {
    margin: 24px 16px 36px;
  }
}
