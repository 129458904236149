
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-summary {
  &__section-wrapper {
    margin-bottom: 64px;
  }

  &__section {
    margin-top: 16px;
  }

  &__section-heading {
    display: flex;
    padding: 0 60px;
    align-items: flex-end;
    position: relative;

    @media screen and (min-width: $min-app-width) {
      padding: 0 100px;
    }

    > .heading {
      display: inline-block;
      margin: 0;
      flex: 1;
      text-align: left;
      color: $color-text-dark;
    }
  }

  &__subheading {
    margin-bottom: 28px;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
  }

  &__item-title {
    color: $color-text;
  }

  &__combined-value {
    display: flex;
    flex-direction: column;

    &-wrapper {
      margin-top: 2px;

      .combined-value__subtitle {
        margin-right: 5px;
        margin-top: $ui-default-measure;
        display: inline-block;
      }
    }
  }

  &__values-list,
  &__values-list li {
    list-style: initial;
  }

  &__values-list {
    margin-left: $ui-default-measure2x;
  }

  &__values-list li {
    margin-bottom: 6px;
  }
}

.base-summary__comment {
  color: $color-text-dark;
  @include font-weight(300);

  textarea.ant-input {
    min-height: 100px;
    resize: none;
    margin-top: 4px;
    @include font-name();
  }

  & > .base-box__heading:first-of-type {
    margin-top: 0;
  }
}

.base-summary__fees {
  color: $color-text;
  margin-top: 40px !important;
  text-align: center;
  font-size: 14px !important;
  line-height: 26px !important;
}

.summary-file {
  display: flex;
  align-items: center;

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    align-self: flex-start;
    margin-right: 10px;

    &--open {
      padding-top: 2px;
      margin-left: 10px;
    }
  }

  &__name {
    word-break: break-all;
  }
}

.base-summary--margin {
  display: block;
  margin-bottom: 10px;
}

.base-summary__address {
  @extend .base-summary__combined-value-wrapper;

  > span {
    margin-bottom: 5px;
  }
}

.delivery-modal {
  width: 700px !important;

  .base-modal__content {
    text-align: left;

    span {
      font-size: 14px;
    }
  }
}
