
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request-header {
  justify-content: center;
  height: 42px;

  > * {
    text-align: center;
  }

  &__back,
  &__cancel {
    text-align: center;
    color: $color-text;
    align-self: center;
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    background-color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    text-transform: uppercase;
  }

  &__cancel {
    display: flex;
    justify-content: flex-end;
  }
}
