
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.how-to-start {
  margin-top: 80px;

  .heading {
    text-align: center;
  }

  &__icons {
    position: relative;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      width: 140px;
      height: 140px;
      background: $color-light-background;
      border-radius: $ui-default-border-radius--circle;
      position: relative;

      > .base-icon,
      > .base-icon svg {
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        margin-bottom: 4px;
      }

      > p {
        @include font(16);
        text-transform: uppercase;
        margin: $ui-default-measure 0 0;
        color: $color-primary;
        text-align: center;
      }
    }

    &-connector {
      @include center();
      width: 60%;
      margin: 0 auto;
      height: 10px;
      background: $color-light-background;
    }
  }

  &__instructions {
    margin-top: 40px;

    &-item > p {
      height: 90px;
    }

    &-list {
      margin: 0 0 $ui-default-measure3x;

      &-item {
        margin-bottom: $ui-default-measure3x;
        display: flex;

        .base-icon {
          margin-right: 12px;
          margin-top: 2px;
        }
      }
    }
  }
}
