
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/steps/style/index.css';

.steps-view {
  min-height: calc(100vh - #{$footer-height});
  height: 100%;
  $module: &;

  &__header {
    border-bottom: 2px solid transparent;
    box-shadow: $ui-default-box-shadow--small;
    position: relative;
    background: $color-white;
  }

  &__steps {
    display: flex;
    justify-content: center;
    margin: 40px auto 40px;
    max-width: 600px;
  }

  &__wrapper {
    min-height: $request-height;
    height: auto;
  }

  &--admin {
    .request-wrapper {
      min-height: calc(#{$request-height} - #{$header-height-admin} - #{$form-padding-top});
    }
  }
}

.ant-steps-item:not(.ant-steps-item-finish) .ant-steps-icon {
  visibility: hidden;
}

.ant-steps-item-process,
.ant-steps-item-finish {

  .ant-steps-item-icon {
    border-width: 2px;
  }
}

.ant-steps-item-process .ant-steps-item-icon {
  background: $color-white;
  border-color: $color-primary;
}

.ant-steps-item-process,
.ant-steps-item-finish {

  > .ant-steps-item-tail:after {
    background-color: $color-primary;
    height: 2px;
  }
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $color-primary;
  background: $color-primary;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $color-white;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 52px;
  padding: 3.5px 16px;
}
