$spaceamounts: ($ui-default-measure, $ui-default-measure2x, $ui-default-measure3x);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{str_slice(inspect($space), 0, -3)} {
      margin-#{$side}: #{$space};
    }

    .p#{str-slice($side, 0, 1)}-#{str_slice(inspect($space), 0, -3)} {
      padding-#{$side}: #{$space};
    }

    .m#{str-slice($side, 0, 1)}i-#{str_slice(inspect($space), 0, -3)} {
      margin-#{$side}: #{$space} !important;
    }

    .p#{str-slice($side, 0, 1)}i-#{str_slice(inspect($space), 0, -3)} {
      padding-#{$side}: #{$space} !important;
    }
  }
}
