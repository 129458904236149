
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.user-common-view {
  &__header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__content {
    min-height: calc(100vh - #{$header-height});
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__title {
    margin: 48px 0 40px;
    text-align: center;

    > h1 {
      text-transform: uppercase;
    }
  }

  &__box {
    margin-bottom: 40px;
    padding: 0;
  }

  &__label {
    font-weight: 300;
    padding-bottom: 2px;
  }

  &__consent {
    .ant-checkbox-inner {
      top: 2px;
    }
  }

  &__submit {
    margin-bottom: 100px;
    button {
      width: 100%;
    }
  }

  &__forgot-password-link {
    color: $color-primary;
    margin-top: -25px;
    margin-bottom: 35px !important; // override for default row margin bottom
  }
}
