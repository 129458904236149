
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-loader {
  &-wrapper,
  &-blur {
    // minus footer top margin
    min-height: calc(100vh - #{$footer-height} - #{$ui-default-measure});
  }

  &-blur > * {
    width: 100%;
  }

  &-wrapper--visible {
    display: block;
    pointer-events: none;
  }

  &-blur--visible {
    pointer-events: none;
    filter: blur(2px);
  }
}
