$color-primary-x-light: #428bca;
$color-primary-light: #295fa6;
$color-primary: #4431af;
$color-primary-dark: #36278c;
$color-primary-x-dark: #101111;

$color-success: #1fb06a;
$color-success--light: #ddf3e9;
$color-review: #1596eb;
$color-rejected: #eb5757;
$color-warning: #fd612c;
$color-warning--light: #fee8e0;
$color-action: #fdae2d;
$color-action--light: #fef3e0;
$color-light-background: #f1f2f2;

$color-input-border: #a5a6a6;

$color-text-dark: #111111;
$color-text: #6d6e6e;
$color-text-light: #d1d2d2;

$color-white: #ffffff;

$color-background-gray: #f9f9f9;
$color-background-purpule-light: rgba(68, 49, 175, 0.05);

$global-font: 'Fira Sans';
$ui-default-measure: 8px;
$ui-default-measure2x: 16px;
$ui-default-measure3x: 24px;
$ui-default-measure4x: 32px;
$ui-default-measure5x: 40px;
$ui-default-measure6x: 48px;
$ui-default-border-radius--circle: 50%;
$ui-default-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$ui-default-box-shadow--small: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
$ui-default-box-shadow--reversed: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);

$request-height: calc(100vh - 196px);
$form-padding-top: 65px;
$header-height: 80px;
$header-height-admin: 60px;
$footer-height: 216px;
$min-app-width: 1150px;
$medium-app-width: 1440px;
