
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.user-groups {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .heading {
    text-align: center;
    width: 100%;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;

    div:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  &__name {
    min-width: 200px;
    word-break: break-word;
  }

  ~ .base-table {
    table-layout: fixed;
  }

  &__empty {
    text-align: center;
  }
}
