
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.form-wrapper {
  &__content {
    padding-top: 40px;
  }

  .base-box:last-of-type {
    margin-bottom: 80px;
  }

  .base-status__wrapper {
    top: 120px;
  }

  .request-wrapper--request-form {
    .base-box:last-of-type {
      margin-bottom: 0;
    }
  }
}
