
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.app-header {
  .container {
    .logo {
      height: 57px;
    }
  }
}
