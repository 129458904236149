.label {
  color: $color-text-dark;
  @include font(16, 300);
  line-height: 26px;
  display: block;
  margin-bottom: $ui-default-measure;
}

.divider {
  border: 0.5px solid $color-text-light;
  height: 0;
  width: 100%;
  margin: 15px 0;

  &--dashed {
    border-style: dashed;
  }

  &--color-primary {
    border-color: $color-primary;
  }
}

.link--underlined {
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.d-none {
  display: none;
}
