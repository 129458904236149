
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

@import '~antd/lib/upload/style/index.css';
@import '~antd/lib/progress/style/index.css';

.base-upload {
  &-input__button {
    margin: $ui-default-measure2x 0 0;
  }

  &__list {
    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: $ui-default-measure2x;

      &--invalid {
        .base-upload__list-name {
          color: $color-warning;
        }
      }

      &-content {
        display: flex;

        &-wrapper {
          display: flex;
          justify-content: space-between;
          position: relative;
        }
      }

      &-replace {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &-upload .ant-upload {
          padding: 0 !important;
        }
      }

      .base-loader-spinner {
        bottom: calc(50% - 12px);
        left: calc(100% - 20px);
      }

      .ant-progress {
        margin-top: $ui-default-measure;
      }

      &-remove {
        margin-left: $ui-default-measure2x;
      }
    }

    &-name {
      padding-right: $ui-default-measure3x;
      margin-left: $ui-default-measure !important;
      word-break: break-all;
    }

    &-actions {
      display: flex;
      align-items: center;
    }
  }
}

.ant-progress-status-success .ant-progress-bg {
  background-color: $color-success;
}

.ant-progress-status-exception .ant-progress-bg {
  background-color: $color-warning;
}

.ant-progress-status-active .ant-progress-bg {
  background-color: $color-primary;
}

.ant-progress-status-success .ant-progress-text {
  color: $color-success;
}

.ant-progress-status-exception .ant-progress-text {
  color: $color-warning;
}

.ant-progress-status-active .ant-progress-text {
  color: $color-primary;
}

.ant-upload {
  width: 100%;
}

.ant-upload.ant-upload-drag {
  border: none;
  background: transparent;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: flex;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  background: lighten($color-primary, 54%);
}
