
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.primary-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: inherit;

  &-content {
    // minus footer top margin. Needed for IE11
    min-height: calc(100vh - #{$footer-height} - #{$ui-default-measure});
  }
}
