
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.client-advisor-badge {
  &__title {
    @include font(16, 300);
    color: $color-text-dark;
    line-height: 26px;
    margin: 0 0 12px;
  }

  &__image {
    background: $color-background-gray;
    margin: 0 0 8px;
    width: 100%;
  }

  &__download-button {
    width: 100%;
  }

  &--request-details {
    .client-advisor-badge {
      &__title {
        @include font(14);
        color: $color-text;
      }

      &__wrapper {
        display: flex;
        align-items: flex-end;
        margin-top: 16px;
      }

      &__image,
      &__download-button {
        max-width: 188px;
      }

      &__image {
        margin: 0;
      }

      &__download-button {
        margin: 0 32px;
        max-height: 36px;
      }
    }
  }
}
