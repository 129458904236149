
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.request__form {
  &-input-offset {
    padding-left: 30px;
  }

  &-multiple-issuers {
    padding-bottom: 25px;

    &-button {
      margin-top: $ui-default-measure;

      &--clear{
        margin-left: $ui-default-measure;
      }
    }
  }

  &-foreign-type {
    .field-container {
      margin-bottom: 0;
    }

    .ant-form-explain {
      display: none;
    }

    &-error {
      color: $color-warning;
      @include font(14);
    }
  }

  &-issue-type {
    margin-top: 36px;
  }
}
