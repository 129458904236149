
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.addendum {
  padding: 60px 0 30px;

  &__submit-button {
    margin-top: $ui-default-measure3x;
  }

  &__table--with-icon {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .base-icon {
      top: 0px !important;
      margin-right: 10px;
      color: black;
    }

    a,
    a:hover {
      text-decoration: underline;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 30px;
    }
  }

  &__table-item {
    > :last-child {
      width: 20%;
    }
  }

  .base-loader-wrapper,
  .base-loader-blur {
    min-height: auto;
  }

  &__info {
    text-align: center;
    padding-top: $ui-default-measure;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
