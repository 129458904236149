
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

$base-status-colors: (
  in_review: $color-action,
  approved: $color-success,
  update_required: $color-action,
  rejected: $color-warning
);

.base-status-bar {
  padding: $ui-default-measure3x 0;

  @each $className, $color in $base-status-colors {
    &--#{$className} {
      background: rgba(red($color), green($color), blue($color), 0.15);
    }
  }

  &--deletion {
    background: #fae1e1;
  }

  &__description {
    white-space: pre-line;
  }
}
