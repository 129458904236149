
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-table {
  border-collapse: collapse;
  width: 100%;

  &__labels {
    border-width: 2px;

    td {
      @include font(14);
      padding-bottom: $ui-default-measure;
    }
  }

  tr {
    border-bottom: 1px solid $color-text-light;

    td {
      @include font(16);
      padding: $ui-default-measure2x $ui-default-measure3x;
      vertical-align: middle;

      .base-icon {
        position: relative;
        top: 2px;
      }

      &.td-date {
        min-width: 150px;
        white-space: nowrap;
      }
    }

    &.tr-no-style {
      border: none;
    }
  }


  .base-loader-spinner {
    bottom: 0;
  }
}
