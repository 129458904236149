
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-form-explain {
    opacity: 0;
    width: 304px;
    color: $color-warning;
  }

  &.error {
    #captcha {
      border: 1px solid $color-warning;
    }

    .ant-form-explain {
      opacity: 1;
    }
  }
}
