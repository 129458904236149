
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.footer {
  color: $color-white;

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      @include font(16);
      padding: 40px 0;
      background-color: $color-primary;
    }
  }

  &__contact {
    display: flex;

    &-item {
      display: flex;
      margin-left: 50px;

      .base-icon {
        margin-right: $ui-default-measure;
      }
    }
  }

  &__copyright {
    padding: $ui-default-measure2x 0;
    background-color: $color-primary-dark;

    span {
      text-transform: uppercase;
      margin-right: 40px;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-scroll-top {
    cursor: pointer;
    transform: rotate(90deg);
  }
}
