
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.app-header-admin {
  background: $color-primary;
  color: $color-white;
  height: $header-height-admin;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__panel {
    height: 100%;
    padding: 0 $ui-default-measure2x;
    display: flex;
    align-items: center;
    background: $color-primary-dark;
    cursor: default;
  }

  &__list {
    padding-bottom: 4px;
    cursor: pointer;

    &--active {
      border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    }
  }

  &__menu {
    display: flex;

    > * {
      margin-left: 40px;
    }
  }
}
