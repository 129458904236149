
  @import "~bxcommon/styles/globals/variables";
  @import "~bxcommon/styles/globals/mixins";
  @import "~bxcommon/styles/globals/z-index";

.base-hint {
  position: absolute;
  // these calc are used to position hints on the right of BaseBox.
  // Smaller than on big screens because of BaseBox padding.
  left: calc(100% + 220px);
  top: 20px;
  width: 180px;

  &--for-full-width-form-box {
    left: calc(100% + 125px);
  }

  &--multiple {
    padding-bottom: 16px;
  }

  li {
    list-style: disc;
    line-height: 19px;
    margin-left: 19px;
    padding-bottom: 8px;
  }
}

.request-form--with-hint,
.request-form--with-close-hint {
  position: relative;
}


.request-form--with-close-hint {
  .base-hint {
    // smaller left to match row size
    left: calc(100% + 140px);
  }
}

